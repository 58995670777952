/* === Styles ===
Base styles for non-component parts of the application.
============================================================================= */
@charset "UTF-8";

// Configuration

@use 'theme' as *;

// Normalize
@import-normalize; // PostCSS Normalize https://create-react-app.dev/docs/adding-css-reset

// Base styles

body {
  font-family: $font-stack;
  font-size: $font-size;
  margin: 0;
  padding: 0;
  background-color: $white;
  color: $text-color-dark;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

// global table styles
table,
th,
td {
  border-collapse: collapse;
  border: solid 1px $gray-2;
  text-align: left;
  vertical-align: middle;
  padding: 5px;
}

table {
  width: 100%;

  @include respond-to(medium) {
    width: 700px;
  }
}

tr:nth-child(odd) {
  background-color: $gray-1;
}

th {
  background-color: $link-color-light;
  color: $white;
  font-weight: 400;
}

tr:hover {
  background-color: $gray-2;
  cursor: pointer;
}

.trash {
  width: 50px;
  text-align: center;
  background-color: $bg-error-color;
  color: $white;

  &:hover {
    background-color: $bg-error-color-hover;
    color: $white;
  }
}
.edit {
  width: 50px;
  text-align: center;
  background-color: hsl(225, 55%, 45%);
  color: $white;

  &:hover {
    background-color: hsl(225, 25%, 25%);
    color: $white;
  }
}

// form styling
input {
  display: block;
  margin-bottom: 10px;
}

input[type='checkbox'] {
  display: inline-block;
  margin: 5px 10px 5px 0px;
}
