// Colors
$white: hsl(0, 0%, 100%);
$gray-1: hsl(0, 0%, 90%);
$gray-2: hsl(0, 0%, 80%);
$gray-3: hsl(0, 0%, 70%);
$gray-4: hsl(0, 0%, 60%);
$gray-5: hsl(0, 0%, 50%);
$gray: $gray-5;
$gray-6: hsl(0, 0%, 40%);
$gray-7: hsl(0, 0%, 30%);
$gray-8: hsl(0, 0%, 20%);
$gray-9: hsl(0, 0%, 10%);
$black: hsl(0, 0%, 0%);

$text-color-dark: $gray-9;
$text-color-light: $gray-1;

$bg-color-dark: $gray-9;
$bg-color-light: $gray-1;

$link-color-light: hsl(120, 50%, 40%);
$link-color-dark: hsl(120, 50%, 60%);

$bg-error-color: hsl(353, 70%, 54%);
$bg-error-color-hover: hsl(353, 70%, 34%);

@mixin theme-dark {
  background-color: $black;
  color: $white;
}
@mixin theme-light {
  background-color: $white;
  color: $black;
}

// Fonts
$font-stack: montserrat, sans-serif;
$font-size: 16px;

// Buttons

// Menus

// Forms
