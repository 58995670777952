@charset "UTF-8";
@use '../../styles/theme' as *;

.list {
  margin-top: 20px;
}

.link {
  color: $black;
}

.buttonWrap {
  position: relative;
  display: block;
  height: 50px;
}

.button {
  display: inline-block;
  position: absolute;
  z-index: 10;
  padding: 12px 18px;
  cursor: pointer;
  background-color: $link-color-light;
  font-weight: bold;
  color: $white;

  &:hover {
    background-color: mix($link-color-light, black, 80%);
  }
}

.input {
  position: absolute;
  z-index: 0;
  top: 8px;
  left: 40px;
  font-size: 17px;
  color: $black;
}

.submit {
  display: inline-block;
  & > * {
    vertical-align: middle;
    text-align: center;
  }

  & > span {
    margin-left: 5px;
  }

  margin: 10px 10px 10px 0;
  padding: 5px 10px;

  font-family: $font-stack;
  font-size: $font-size;

  border: solid 1px $gray-2;
  background-color: $link-color-light;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: mix($link-color-dark, black, 60%);
  }
}

.section {
  width: 100%;
  margin-top: 20px;

  @include respond-to(medium) {
    width: 700px;
  }
}
