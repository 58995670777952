@charset "UTF-8";
@use '../../styles/theme' as *;

/* === Layout ===
wrapper             Wrapper to extend the page.
    navbar          Navbar and footer don't grow.
    main            Main area grows to min-height or content.
        navigation  Navigation on top of content in mobile.
        content     Navigation on the left side on medium+.
    footer
wrapper
============================================================================= */

.wrapper {
  // control page stretch with flexbox
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  // use flexbox to arrange navigation and content
  display: flex;

  // stretch container to page length and content size
  flex: 1 1 auto;

  // set navigation above content
  flex-direction: column;
}

.content {
  position: relative;
  padding: 50px 20px 20px 20px;

  background-color: $white;
  color: $text-color-dark;

  flex: 1 1 auto;

  // push content to the right to show sidenav on desktops
  @include respond-to(medium) {
    padding-left: 220px;
  }

  transition: all 0.3s;
}
