@charset "UTF-8";
@use '../../styles/theme' as *;

.background {
  top: 0;
  left: 0;
  z-index: 1000;
  position: absolute;
  background-color: $gray;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
}

.window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 20px;

  z-index: 1000;
  background-color: $white;
}

.cancel,
.confirm {
  display: inline-block;
  & > * {
    vertical-align: middle;
    text-align: center;
  }

  & > span {
    margin-left: 5px;
  }

  margin: 10px 10px 10px 0;
  padding: 5px 10px;

  font-family: $font-stack;
  font-size: $font-size;

  border: solid 1px $gray-2;

  color: $white;
  cursor: pointer;
}

.cancel {
  background-color: $bg-error-color;
  &:hover {
    background-color: mix($bg-error-color, black, 60%);
  }
}

.confirm {
  background-color: $link-color-light;
  &:hover {
    background-color: mix($link-color-dark, black, 60%);
  }
}
