@charset "UTF-8";
@use '../../styles/theme' as *;

.footer {
  flex: 0 1 50px;
  z-index: 100;
  background-color: mix($bg-color-dark, black, 80%);
  color: $text-color-light;
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    color: $white;
    &:hover {
      color: $link-color-dark;
    }
  }
}
