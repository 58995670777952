@charset "UTF-8";
@use '../../styles/theme' as *;

.navButton {
  display: inline-block;
  & > * {
    vertical-align: middle;
    text-align: center;
  }

  & > span {
    margin-left: 5px;
  }

  margin: 10px 10px 10px 0;
  padding: 5px 10px;

  border: solid 1px $gray-2;
  background-color: $link-color-light;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: mix($link-color-dark, black, 60%);
  }
}

.submit {
  display: inline-block;
  & > * {
    vertical-align: middle;
    text-align: center;
  }

  & > span {
    margin-left: 5px;
  }

  margin: 10px 10px 10px 0;
  padding: 5px 10px;

  font-family: $font-stack;
  font-size: $font-size;

  border: solid 1px $gray-2;
  background-color: $link-color-light;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: mix($link-color-dark, black, 60%);
  }
}
