@charset "UTF-8";
@use '../../styles/theme' as *;

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  // use flexbox and align items to center elements in the container
  display: flex;
  align-items: center;

  background-color: $white;
  color: $gray-9;
  border-bottom: solid 1px $gray-2;
}

.left {
  display: flex;
  flex: 0 1 0;
  justify-content: center;
  align-items: center;

  // @include respond-to(medium) {
  //   display: none;
  // }
}

.middle {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // @include respond-to(medium) {
  //
  // }
}

.logo {
  height: 50px;
  cursor: pointer;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 0 1 0;
}

.sideToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;

  &:hover {
    color: $link-color-light;
  }
}

.userToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;

  &:hover {
    color: $link-color-light;
  }
}

.sideMenu {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100vh;
  width: 200px;
  z-index: 90;
  overflow: auto;

  // text color gets overwritten by link color
  // define text color in .item
  background-color: $white;
  border: solid 1px $gray-2;

  opacity: 1;
  visibility: visible;
  transition: all 0.3s;

  &.hide {
    left: -200px;
    opacity: 0;
    visibility: hidden;
  }
}

.userMenu {
  position: fixed;
  top: 50px;
  right: 0;
  height: auto;
  width: 200px;
  z-index: 90;
  overflow: auto;

  background-color: $white;
  color: $text-color-dark;
  border: solid 1px $gray-2;

  opacity: 1;
  visibility: visible;
  transition: all 0.3s;

  &.hide {
    right: -200px;
    opacity: 0;
    visibility: hidden;
  }
}

.item {
  display: flex;
  align-items: center;

  padding: 10px;
  border-left: solid 0px $white;

  color: $gray-9;

  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    color: $link-color-light;
    border-left: solid 5px $link-color-light;
  }
}

.label {
  margin-left: 20px;
}
